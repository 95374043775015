*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

body {
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

:root {
    --primary-font: 'Montserrat', sans-serif;
    --secondary-font: 'Gentium Plus', serif;
    --titles-font: 'Forum', cursive;
    --primary-background-color: linear-gradient(90deg,#49495d,#05191f);
    --hover-color: #ccb25c;

}

.App {
    overflow-x: hidden;
    font-family: var(--primary-font);
}

.hover-links {
    color: white;
    transition: color 0.2s;
}

.color {
    color: var(--hover-color);
}

.hover-links:hover {
    color: var(--hover-color);
}

a:link {
  color: white; 
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  color: white;
  text-decoration: none;
}

ul li,
ol li {
  list-style: none;
}

ul li a:link,
ol li a:link {
  color: black;
  text-decoration: none;
}

ul li a:visited,
ol li a:visited {
  color: black; 
  text-decoration: none;
}

::-webkit-scrollbar {
    background-color: #021114;
    width: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3em;
    width: 4px;
    background-color: rgb(255, 255, 255);
}

/* Home */

.home-concerts-container-sizes {
    display: none;
}

.visible {
    opacity: 1;
}
  
.hidden {
    opacity: 0;
    transition: opacity 2s ease;
}

.cello-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgb(2, 17, 20);
    z-index: 100;
    justify-content: center;
    align-items: center;
}

.cello-image {
    height: 70%;
    width: auto;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    pointer-events: none;
    animation: animateDash 3.7s linear forwards;
}

@keyframes animateDash {
    to {
      stroke-dashoffset: 200;
    }
}

.home-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    width: 100%; 
    height: 100vh;
    overflow: hidden;
    scrollbar-gutter: stable;
}

.header-overlaid {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 100vw;
    height: fit-content;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
    transition: opacity 0.8s ease, top 0.7s ease;
    z-index: 2;
}

.header-hidden {
    top: -50%;
    opacity: 0;
}

.main-header {
    width: 100vw;
    display: flex;
    height: fit-content;
    min-height: 60px;
    flex-direction: row;
    z-index: 3;
    padding: 1rem 5% 1rem 3%;
    justify-content: space-between;
    align-items: center;
}

.header-name {
    width: 40%;
}

.name {
    font-size: 2.5rem;
    font-weight: 300;
    font-family: var(--primary-font);
    width: fit-content;
    color: rgba(255, 255, 255, 0.866)
}

.image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.background-image {
    position: absolute;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    max-width: auto;
}

.header-navigation {
    display: flex;
    width: 40%;
    flex-direction: column;
}

.navigation {
    font-size: 1.3rem;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navigation-links {
    font-size: 1rem;
    color: white;
    cursor: pointer;
    transition: color 0.5s;
}

.navigation-links:hover {
    color: var(--hover-color);
}

.navigation-extra {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width: 40%;
    margin-top: 3%;
    justify-content: flex-end;
}

.navigation-social-media {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
}

.navigation-icons {
    width: 30px;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
}

.navigation-languages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 500;
    width: 30%;
}

.navigation-languages-home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    width: 5%;
    height: 10vh;
}

.links-circle {
    display: flex;
    width: 5vh;
    height: 5vh;
    background-color: #00000079;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.quote-wrapper {
    position: absolute;
    width: 28vw;
    display: flex;
    top: 30vh;
    left: 3vw;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.281);
    display: none;
}

.home-quote {
    color: white;
    font-size: 1.5rem;
    letter-spacing: .09rem;
    font-style: italic;
    opacity: 1;
    width: fit-content;
    text-align: center;
}

.quoter {
    margin-top: 1.3rem;
    font-size: 1.2rem;
    color: white;
    opacity: .8;
    font-style: italic;
}

.home-concerts-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    bottom: 5vh;
    left: 3vw;
    width: 85vw;
    height: 15vh;
    gap: 5vw;
}

.concerts-anchors {
    height: 100%;
    display: flex;
    align-items: flex-start;
}

.home-concert {
    display: flex;
    cursor: pointer;
    width: fit-content;
    height: 100%;
    justify-content: flex-start;
    padding: 1%;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.236);
    border-radius: .8rem;
    transition: background-color 0.3s;
}

.home-concert:hover {
    background-color: rgb(205, 211, 212);
}

.home-concert:hover .items {
    color: black;
}

.concert-date {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 2% 0 2% 5%;
}

.concert-location-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    height: 100%;
    justify-content: space-evenly;
    margin: 0 1.5rem;
}

.items {
    display: flex;
    align-self: flex-start;
    font-size: 1.4rem;
    color: white;
    opacity: .9;
    transition: color .3s;
    white-space: normal;
    word-wrap: break-word;
}

.place-concert {
    font-size: 1rem;
}

.concert-month {
    font-size: 1.1rem;
}

.home-upcoming-events-container {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    color: white;
    font-size: 1.2rem;
    transition: color 0.2s;
}

.home-upcoming-events-container:hover .home-upcoming-events {
    color:  rgb(205, 211, 212);
}

.margin-small {
    margin-right: .5rem;
}

/* Bio */

.main-bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
}

.bio-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15vh;
}

.bio-quote-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 40vh;
    width: 40vw;
    display: none;
}

.main-bio-container {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    padding-left: 7vw;
    padding-right: 7vw;
}

.bio-heading {
    border: 1.5px solid #ceced3e5;
    padding: 3% 5% 3% 5%;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 3rem;
    font-family: var(--primary-font);
    font-weight: 400;
    color: white;
    margin-bottom: 6vh;
}

.bio-text {
    color: white;
    font-size: 1.2rem;
    margin-top: 5vh;
}

.bio-container {
    width: 100%;
    height: 100%;
    background-image: var(--primary-background-color);
    overflow-x: hidden;
}

.image-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: left;
  position: relative;
  background-color: black;
  overflow: hidden;
  background-clip: padding-box;
  height: 100vh; 
}

.bio-image {
  transition: opacity 2s ease, transform 3s ease;
  position: relative;
  width: 100%;
  height: auto;
}

.bio {
    display: flex;
    align-self: center;
}

.left {
    margin-top: 5vh;
    padding-right: 10%;
    margin-bottom: 5vh;
}

.bio-image-phone {
    right: 5vw;
    position: relative;
    height: auto;
}

.down-arrow-bio {
    cursor: pointer;
    position: absolute;
    bottom: 3vh;
    left: 50%;
    display: flex;
    width: 27px;
    height: 27px;
}

.down-arrow {
    fill: white;
    width: 100%;
    height: 100%;
    transition: fill 0.5s;
}

.down-arrow:hover {
    fill: var(--hover-color);
}

/* Concerts */

.concert-container {
    width: 100%;
    height: 100%;
    background-image: var(--primary-background-color);
    overflow-x: hidden;
}

.calendar-body {
    width: 100%;
    height: 100%;
    padding-top: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.concert-upcoming-container {
    position: relative;
    width: fit-content;
    display: flex;
    
}

.concert-heading {
    font-size: 3rem;
    font-family: var(--primary-font);
    font-weight: 400;
    color: white;
}

.calendar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 15%;
    padding: 0 8vw 0 8vw;
}

.concert-calendar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #ceced3e5;
    justify-content: flex-start;
    padding-right: 0;
    color: white;
    font-weight: 300;
    font-family: var(--primary-font);
    height: 70vh;
}

.calendar-image-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    overflow: hidden;
}

.calendar-image {
    position: relative;
    height: 120%;
    top: -10%;
}


@media (min-width: 700px) {
    .external {
        top: 0;
        height: 100%;
        right: 45%;   
    }
}

.calendar-date {
    font-size: 1.3rem;
    font-weight: 300;
    color: white;
    margin-bottom: 2%;
}

.title-margin {
    margin-left: .5rem;
}

.concert-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    height: 70vh;
    padding: 4% 0 4% 5%;
}

.concert-name {
    padding-right: 3%;
    font-size: 2rem;
    font-weight: 100;
    margin-bottom: 3%;
}

.last {
    margin-bottom: 15%;
}

.calendar-date-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start; 
    justify-content: flex-start;
}

.concert-location {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.concert-place {
    font-weight: 100;
    font-size: 1.2rem;
}

.concert-place2 {
    width: fit-content;
    max-width: 50%;
    font-weight: 100;
    font-size: 1rem;
}

.players-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3%;
    justify-content: space-between;
}

.pieces-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3%;
}

.margin-bottom {
    margin-bottom: .5rem;
}

.margin-right {
    margin-right: 5%;
}

.button-more {
    margin-top: 3%;
    border: 1.5px solid white;
    padding: 1.5% 7% 1.5% 7%;
    transition: color 0.5s, background-color 0.5s;
}

.button-more:hover {
    background-color: white;
    color: rgb(9, 35, 41);
}

hr {
    width: 80%;
    height: 1px;
    margin-top: 3%;
    margin-bottom: 3%;
    opacity: .8;
}
/* Media */

.media-container {
    width: 100%;
    height: 100%;
    background-image: var(--primary-background-color);
    overflow-x: hidden;
}

.media-body {
    width: 100%;
    height: 100%;
    padding-top: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media-upcoming-container {
    position: relative;
    width: fit-content;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    height: 20%;
    justify-content: space-between;
}

.media-heading {
    display: flex;
    align-self: flex-start;
    font-size: 3rem;
    font-family: var(--primary-font);
    font-weight: 300;
    color: white;
}

.contact-heading {
    display: flex;
    align-self: flex-start;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 2rem;
    font-family: var(--primary-font);
    font-weight: 300;
    color: white;
}

.media-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 0 5vw 10vh 5vw;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    width: 50vw;
    height: fit-content;
    margin-bottom: 2vh;
}

.margin2 {
    margin-left: .5rem;
}

.btn-media {
    cursor: pointer;
    border: 1.2px solid #ceced3;
    padding: 4% 10% 4% 10%;
    color: white;
    opacity: .4;
    font-size: 1.2rem;
    transition: opacity .3s;
}

.btn-media:hover {
    opacity: 1;
}

.videos-container {
    margin-top: 7vh;
    width: 100%;
    height: 120vh;
    display: grid;
    justify-content: left;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr; 
    row-gap: 7vh;
    column-gap: 3rem;
    margin-bottom: 8vh;
}

.video-responsive {
    position: relative;
}

#video {
    width: 450px;
    height: 350px;
}

.photos-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 20fr 20fr;
    margin-bottom: 8vh;
    height: 100%;
}

.photos-container img {
    cursor: pointer;
    width: 350px;
}

.photos-container,
.videos-container {
  opacity: 0;
  transition: opacity 1s ease,  transform .5s ease;;
}

.photos-container.visible,
.videos-container.visible {
  opacity: 1;
  transform: translateY(-10px);
}

.btn-media.active {
    pointer-events: none;
    opacity: 1;
}

/* Contact */

.svg {
    width: 2rem;
}

.contact-container {
    width: 100vw;
    height: 100%;
    background-image: var(--primary-background-color);
    overflow-x: hidden;
}

.contact-body {
    width: 100%;
    height: 100vh;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    height: 80vh;
    width: 100%;
    padding: 2% 5% 0 7%;
    z-index: 10;
}

.contact-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: space-between;
    margin-top: 10%;
    height: 40%;
}

.contact-links-container {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 55%;
}

.links-contact {
    font-family: var(--titles-font);
    font-size: 1.2rem;
    transition: color .2s;
}

.contact-links-container:hover .links-contact {
    color: var(--hover-color);
}

.margin {
    margin-left: 2.5rem;
}

.fit-content {
    width: fit-content;
    margin-top: 5%;
}

.hamburger-container{
    display: none;
}

.hamburger {
    width: 3rem;
}

.hamburger-menu-container {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 35%;
    transition: top 1s;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #000000;
    z-index: 30;
}

.hamburger-header {
    width: 100%;
    left: 0;
    right: 0;
}

.hamburger-menu-container.open {
    top: 0;
}

.hamburger-menu-container.closed {
    top: -150%;
}

.header-navigation-hamburger {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 50%;
    width: 30vw;
    align-items: flex-start;
    justify-content: space-between;
}

.navigation-social-media-hamburger {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3vh;
}

.navigation-languages-hamburger {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    font-size: 1.6rem;
    padding-bottom: 2vh;
}

.navigation-hamburger {
    display: flex;
    flex-direction: column;
    height: 30%;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 1.8rem;
}

/* Footer */

.footer {
    width: 100%;
    height: 10vh;
    background-color: white;
}

.padding-line {
    margin-left: .5rem;
    margin-right: .5rem;
}

.footer-container {
    padding: 0 4% 0 4%;
    font-size: 1.2rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bottom-zero {
    position: absolute;
    bottom: -10vh;
}

.imprint-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.color-a {
    color: black;
}


.download-presskit {
    font-size: 1.6rem;
    margin-left: 10%;
    margin-bottom: 5vh;
    color: white;
}

.video-name {
    margin-top: 2vh;
    font-size: 1.125rem;
    letter-spacing: .2px;
    color: white;
    font-family: Inter-VQ,sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.video-container {
    margin-bottom: 8vh;
}

/* Carousel */

.custom-carousel {
    background-color: rgba(5, 34, 41, 0.959);
    width: 100vw;
    height: 100vh;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 0 2vh 0;
}

.close-button {
    position: absolute;
    top: 5%;
    right: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 2rem;
    color: #bdbdbd;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.close-button:hover {
    color: var(--hover-color);
}

.left-button {
    position: absolute;
    top: 50%;
    left: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 3rem;
    color: #bdbdbd;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.left-button:hover {
    color: var(--hover-color);
}

.right-button {
    position: absolute;
    top: 50%;
    right: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 3rem;
    color: #bdbdbd;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.right-button:hover {
    color: var(--hover-color);
}

.main-image {
    height: 80vh;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.visibleImage {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.hiddenImage {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.main-image img {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: auto;
}

.thumbnails {
    display: flex;
    margin-top: 3vh;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    justify-content: space-between;
}

.thumbnail img {
    height: 13vh;
    width: auto;
    cursor: pointer;
}

.thumbnail.selected {
    border: 2px solid rgb(255, 255, 255);
}

/* Finish */

.header-name-fi{
    width: 45%;
}

.header-navigation-fi {
    width: 40%;
}

/* Different Sizes */

@media (max-width: 768px) {
    .bio-image-phone {
        position: relative;
        width: 130%;
        left: 10vw;
    }
}

/* Styles for maximum width of 1024px */

@media (max-width: 1024px) {
    .down-arrow {
        fill: white;
        width: 100%;
        height: 100%;
        transition: fill 0.5s;
    }

    .bio-download {
        width: fit-content;
        margin-bottom: 5%;
    }

    .bio-quote-wrapper {
        left: 1%;
        width: 50vw;
    } 

    .bio-wrapper {
        margin-bottom: 4%;
    }

    .right-button {
        top: 40%;
    }

    .left-button {
        top: 40%;
    }

    .main-image img {
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 69vw;
        height: auto;
    }

    .thumbnails {
        margin-top: 0;
        overflow-x: scroll;
        white-space: nowrap; 
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .thumbnails div {
        margin-right: 5%;
    }

    .thumbnail img  {
        height: 14vh;
    }

    .thumbnails::-webkit-scrollbar {
        display: none;
    }

    .home-container {
        scrollbar-gutter: auto;
    }

    .media-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .videos-container {
        margin-bottom: 5vh;
    }

    .contact-body {
        padding-top: 0;
    }

    .contact-main {
        align-items: flex-start;
        justify-content: flex-start;
        height: 70vh;
        width: 60%;
        align-self: self-start;
    }

    .contact-heading {
        font-size: 2.7rem;
    }

    .media-upcoming-container {
        align-self: flex-start;
        width: 100%;
        height: 15%;
    }

    .contact-main-container {
        align-self: flex-start;
        margin-top: 20%;
    }

    .contact-links-container {
        font-size: 1.5rem;
        color: #403c3c;
    }

    .margin  {
        margin-left: .5rem;
    }
    
    .links-contact {
        color: #ffffff;
        font-size: 1.6em;
    }

    .main-header {
        padding-right: 2%;
        justify-content: space-between;
        padding-left: 2%;
    }

    .navigation  {
        font-size: .8em;
    }

    .media-heading {
        font-size: 2.5em;
    }

    .calendar-wrapper {
        padding: 0 1vw 0 1vw;
    }

    .concert-wrapper {
        padding-left: 2%;
        padding-right: 1%;
        height: 50vh;
    }

    .concert-calendar-container {
        height: 75vh;
    }

    .about-image {
        top: 0;
    }

    .home-concerts-container-sizes {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 5%;
        width: 100%;
        left: 0;
        align-items: flex-start;
        padding-left: 3%;
        padding-right: 3%;
    }

    .concerts-container-sizes {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3rem;
        max-width: 100%;
        width: 1000px; 
        overflow-x: scroll;
        white-space: nowrap; 
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .concerts-container-sizes::-webkit-scrollbar {
        display: none;
    }

    .items {
        font-size: 1.1em;
    }

    .concert-location-container {
        align-self: center;
        font-size: 1.3em;
    }

    .home-concert {
        width: fit-content;
        padding: .6em 1.5em .6em 1.5em;
    }

    .navigation-extra{
        width: 40%;
    } 

    .navigation {
        font-size: 1.5em;
    }

    .navigation-languages {
        font-size: 1.8em;
        width: 35%;
    }

    .size-upcoming-concerts-container {
        display: flex;
        flex-direction: row;
        margin-top: 6%;
        margin-bottom: 7%;
        border-radius: .8em;
        padding: .6em 1.5em .6em 1.5em;
        background-color: rgba(255, 255, 255, 0.236);
    }

    .home-upcoming-events-container {
        font-size: 2em;
    }

    .background-image2{
        position: absolute;
        height: 100%;
        left: -45%;
    }

    .header-navigation {
        justify-content: space-between;
        height: 5vh;
        width: 40%;
    }

    .header-navigation-fi {
        width: 45%;
    }

    .photos-container {
        display: grid;
        padding-left: 5%;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        height: fit-content;
        row-gap: 2vh;
    }

    .photos-container img {
        width: 390px;
    }

    .videos-container {
        height: fit-content;
        margin-top: 0;
    }

    .home-concerts-container {
        display: none;
    }

    .quote-wrapper {
        width: 45vw;
        top: 17vh;
        background-color: #00000049;
        padding: 2% 3% 2% 3%;
        border-radius: .8em;
    }

    .home-quote {
        opacity: 1;
        font-size: 1.8em;
    }

    .quoter {
        opacity: 1;
        font-size: 1.8em;
        margin-top: 5%;
    }
    
    .header-name {
        width: 35%;
    }

    .name {
        font-size: 3.3em;
    }

    .header-name-fi{
        width: 35%;
    }

    .download-presskit {
        color: white;
        margin-bottom: 5vh;
    }

    .media-heading {
        font-size: 2.8em;
        margin-bottom: 8vh;
    }
}

@media (max-width: 1000px) {
    .right-button {
        display: none;
    }

    .left-button {
        display: none;
    }

    .main-image img {
        min-width: 70vw;
        height: auto;
    }
    
    .text-hamburger {
        font-size: 2rem;
    }

    .footer-container {
        font-size: 1.1rem;
    }
    
    .links-contact {
        color: #ffffff;
        font-size: 1.2em;
    }

    .imprint-container {
        width: 22vw;
    }

    .home-concerts-container-sizes {
        padding-right: 0;
    }


    .home-concert {
        font-size: .9em;
    }

    .navigation-links {
        font-family: var(--primary-font);
    }

    .header-overlaid{
        padding-top: 4%;
        padding-bottom: 3%;
        z-index: 100;
    }

    .hamburger-main-header {
        width: 100vw;
        display: flex;
        height: fit-content;
        flex-direction: row;
        z-index: 3;
        scrollbar-gutter: stable;
        font-size: .8em;
        font-weight: 300;
        padding: 1% 5% 1% 5%;
        justify-content: space-between;
    }

    .header-name {
        width: fit-content;
    }
  
    .header-navigation {
        display: none;   
    }

    .hamburger-container {
        display: block;
    }

    .button-container {
        width: 40vw;
    }

    .photos-container {
        padding: 0%;
        column-gap: 2vw;
        row-gap: 2vh;
    }

    .photos-container img {
        width: 330px;
    }

    .videos-container {
        margin-top: 0vh;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 7vh;
    }

    .download-presskit {
        color: white;
        margin-bottom: 7vh;
    }
}

@media (max-width: 845px) {
    .home-concert {
        min-width: 45vw;
    }
}

@media (max-width: 715px) {
    .home-concert {
        min-width: 55vw;
    }
}

@media (max-width: 595px) {
    .home-concert {
        min-width: 65vw;
    }
}

@media (max-width: 540px) {
    .name {
        font-size: 1.7rem;
    }
    
    .footer-container {
        font-size: .8rem;
    }

    body {
        scrollbar-gutter: auto;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 3em;
        width: 5px;
        background-color: transparent;
    }

    .contact-main {
        height: 70vh;
    }

    .contact-main-container {
        height: 50%;
    }

    .social-media-container {
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .media-heading {
        font-size: 2em;
    }

    .display {
        font-size: .8em;
    }

    .display2 {
        font-size: .8em;
    }

    .calendar-wrapper {
        height: 100%;
        margin-top: 20%;
        padding: 0 5% 0 5%;
    }

    .calendar-image-container {
        width: 100%;
        height: 80%;
        overflow: hidden;
    }

    .concert-wrapper {
        align-self: flex-start;
        width: 100%;
        height: 100%;
        padding: 6% 3% 6% 3%;
        gap: 1rem;
    }

    .concert-name {
        font-size: 2em;
    }

    .calendar-image {
        width: 100%;
    }

    .concert-calendar-container {
        height: 100%;
        flex-direction: column;
    }

    .concert-place {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 1em;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;
    }

    .title-margin {
        display: none;
    }

    .quote-wrapper {
        display: none;
    }

    .items {
        font-size: 1em;
    }

    .home-upcoming-events-container {
        font-size: 1.3em;
    }

    .imprint-container {
        width: max-content;
    }

    .header-navigation-hamburger {
        height: 60%;
        width: 50vw;
    }

    .hamburger {
        width: 2.2em;
    }
    
    .concert-heading {
        font-size: 2rem;
    }

    .concert-upcoming-container {
        margin-bottom: 2%;
    }

    .button-container {
        width: 80vw;
    }

    .photos-container {
        padding: 0%;
        display: flex;
        flex-direction: column;
    }

    .photos-container img {
        width: 100%;
    }

    #video {
        width: 100%;
    }
}
@media (max-width: 530px) {

    .bio-image-phone {
        position: relative;
        align-self: center;
        top: 0;
        height: 100%;
        width: auto;
        left: 0;
        right: 0;
    }

    .bio-quote-wrapper {
        display: none;
    }

    .right-button {
        display: none;
    }

    .left-button {
        display: none;
    }

    .close-button {
        top: 1%;
    }

    .custom-carousel  {
        background-color: rgba(5, 34, 41, 0.99);
    }

    .main-image img {
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        height: auto;
    }

    .thumbnail img {
        height: 10vh;
    }

    .thumbnails div {
        margin-right: 6%;
    }
    
    .text-hamburger {
        font-size: 1.5rem;
    }


    .hamburger-menu-container {
        height: 100vh;
        display: flex;
        padding-top: 45%;
    }

    .svg {
        width: 2rem;
    }

    .tatu-copyrights {
        text-align: right;
    }

    .made-by {
        display: none;
    }

    .contact-heading {
        font-size: 1.7em;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .contact-main-container {
        height: 35%;
        width: 100%;
    }

    .social-media-container {
        margin-top: 0;
        height: fit-content;
        width: 90%;
        flex-direction: column;
    }

    .background-image2 {
        height: 120%;
        width: auto;
        left: -40%;
    }

    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .background-image3 {
        position: relative;
        background-size: cover;
        height: 105%;
    }

    .contact-main {
        position: absolute;
        display: flex;
        align-self: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        bottom: 1%;
        height: 90%;
        width: 70%;
        padding-top: 65%;
    }

    .media-upcoming-container {
        padding-left: 5%;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        height: 20%;
    }

    .contact-main-container {
        padding-left: 5%;
    }

    .items {
        font-size: .9rem;
    }

    .concert-location-container {
        margin-left: .9rem;
    }
    
    .concert-location {
        display: flex;
        flex-direction: column;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;
        width: 70%;
    }
        
    .home-concert {
        width: fit-content;
        padding: .8rem .2rem;
    }

    .download-presskit {
        margin-bottom: 15vh;
        margin-left: 10%;
        font-size: 1em;
        color: white;
    }
}

@media (max-width: 368px) {
    .concerts-container-sizes {
        gap: 1rem;
    }

    .home-concert {
        min-width: 75vw;
    }
}

@media (max-width: 300px) {
    .items {
        font-size: .65em;
    }

    .home-upcoming-events {
        font-size: .9rem;
    }
}

@media (min-width: 1440px) {
    
    .background-image {
        height: 100%;
        width: auto;
        left: -40%;
    }

    .header-navigation-fi {
        width: 40%;
    }

    .navigation{
        font-size: 2em;
    }

    .home-concerts-container {
        width: 90vw;
    }

    .concerts-anchors {
        display: flex;
    }

    .home-concert {
        padding: 1rem;
        height: 100%;
        align-self: center;
    }

    .home-concert div {
        font-size: 2rem;
    }

    .home-upcoming-events {
        font-size: 1.5em;
    }

    .quoter {
        font-size: 2em;
    }

    .home-quote {
        font-size: 2em;
    }

    .quote-wrapper {
        width: 30vw;
    }
}

@media (min-width: 2560px) {
    .background-image {
        width: 100vw;
        height: auto;
        left: 0;
    }

    .links-contact {
        font-size: 2.5rem;
    }

    .contact-heading {
        font-size: 3rem;
    }

    .navigation{
        font-size: 2rem;
    }

    .home-concerts-container {
        width: 90vw;
    }

    .home-concert {
        font-size: 1.5em;
        height: 50%;
        width: 20vw;
        align-self: center;
    }

    .home-upcoming-events {
        font-size: 1.5em;
    }

    .quoter {
        font-size: 2em;
    }

    .home-quote {
        font-size: 2em;
    }

    .quote-wrapper {
        width: 30vw;
    }
}

.bio-download {
    margin-top: 10vh;
    align-self: center;
}

@media (max-width: 2000px) 
    and (min-width: 1440px) {
    
    .background-image {
        width: 100%;
        height: auto;
        left: 0;
    }
}

@media (min-width: 1440px) {
    
    .download-presskit {
        font-size: 2rem;
    }

    .contact-heading {
        font-size: 3rem;
    }

    .links-contact {
        font-size: 2rem;
    }

    .media-heading {
        font-size: 4rem;
    }

    .bio-text {
        font-size: 1.5rem;
    }

    #video {
        width: 600px;
        height: 450px;
    }

    .video-name {
        font-size: 1.6rem;
    }
}

@media (min-device-height: 280px)
  and  (max-device-height: 480px)
  and (orientation: landscape) {

    .concert-calendar-container {
        height: 100%;
    }

    .hamburger-menu-container {
        padding-top: 30vh;
        overflow-y: scroll;
        height: 100%;
    }

    .image-wrapper {
        height: 100%;
    }

    .background-image2 {
        left: 0;
        width: 100%;
        height: auto;
    }

    .quote-wrapper {
        display: none;
    }

    .home-upcoming-events-container {
        font-size: 1rem;
    }

    .items {
        font-size: .9rem;
    }

    .home-concert {
        width: 35vw;
    }

    .bio-quote-wrapper {
        display: none;
    }

    .image-container {
        height: 170vh;
    }

    .bio-image-phone {
        width: 100%;
        height: auto;
        top: -20vh;
        left: 0;
        right: 0;
    }

    .calendar-image-container {
        display: none;
    }

    .concert-wrapper {
        width: 100%;
        height: 100%;
    }

    .button-container {
        width: 50vw;
    }

    .main-image img {
        min-width: 0;
        height: 70%;
        width: auto;
    }

    .photos-container img {
        width: 250px;
    }

    .contact-heading {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 2rem;
    }
    
    .contact-links-container {
        font-size: .8rem;
    }

    .svg {
        width: 1rem;
    }
}

@media (min-device-height: 540px)
  and (max-device-height: 719px)
  and (orientation: landscape) {

    .background-image {
        left: 0;
        height: 100vh;
        width: auto;
    }

    .background-image2 {
        left: 0;
        min-height: 100vh;
        min-width: 100vw;
    }

    .concert-heading {
        font-size: 4rem;
    }

    .concert-calendar-container {
        height: 75vh;
    }

    .calendar-image-container {
        height: 100%;
    }

    .concert-wrapper {
        align-self: flex-start;
        justify-content: space-between;
        height: 100%;
    }

    .main-image img {
        height: 75vh;
        width: auto;
        min-width: 0;
    }

    .media-heading {
        margin-bottom: 5vh;
    }
}

@media (min-device-height: 540px)
  and (max-device-height: 768px)
  and (orientation: landscape) {

    .image-container {
        height: 150vh;
    }
    
    .bio-image-phone {
        top: -20vh;
    }

    .background-image2 {
        left: 0;
    }
}